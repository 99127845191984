import { StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { amplitude } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga } from '@helpers/ga'
import { OrderEventsContextProvider } from '@helpers/order-events'
import { HeaderContextProvider } from '@layouts/context'
import { ModalContextProvider } from '@modals/core/provider'
import { NextUIProvider } from '@nextui-org/system'
import { CookieAlert } from '@share/cookie-alert'

import { StaticRoutes } from './static-routes'

amplitude.init()
facebookPixel.init()
ga.init()

hydrateRoot(
  document.getElementById('app')!,
  <StrictMode>
    <NextUIProvider>
      <BrowserRouter>
        <OrderEventsContextProvider>
          <ModalContextProvider>
            <HeaderContextProvider initialState={{}}>
              <StaticRoutes />
              <CookieAlert />
            </HeaderContextProvider>
          </ModalContextProvider>
        </OrderEventsContextProvider>
      </BrowserRouter>
    </NextUIProvider>
  </StrictMode>,
)
